
module.exports =
  metaInfo:
    title: 'Кругосветная экономия: путешествуем по средствам!'
    meta: [
      { name: 'description', content: 'Как не пойти по миру, но посмотреть его: лучшие способы экономить на путешествиях, авиабилетах, отеле и развлечениях в России и заграницей. Только в Энциклопедии Экономии ХаниМани!' }
      { name: 'keywords', content: 'экономить на путешествиях, экономить на авиабилетах, экономия в поездках, сэкономить на отеле' }
      { name: 'human_title', content: 'Кругосветная экономия: путешествуем по средствам!' }
      { name: 'human_subtitle', content: 'Как и мир посмотреть, и без штанов не остаться' }
    ]
